<template>
  <CModal
    :show.sync="show"
    :close-on-backdrop="false"
    :centered="true"
    :color="color"
    :size="size"
  >
    <el-form label-width="180px" label-position="top">
      <el-form-item label="Time Range">
        <el-date-picker class="ml-4 w-75" v-model="start_time" type="datetime" placeholder="From" />
      </el-form-item>
      <el-form-item>
        <el-date-picker class="ml-4 w-75" v-model="end_time" type="datetime" default-time="23:59:59" placeholder="To" />
      </el-form-item>
    </el-form>

    <template #header>
      <h6 class="modal-title">{{ title }}</h6>
      <CButtonClose @click="onCancel" class="text-white"/>
    </template>
    <template #footer>
      <CButton @click="onOkay" color="primary">{{ok_text}}</CButton>
      <CButton @click="onCancel" color="secondary" v-if="cancel_text !== null">{{cancel_text}}</CButton>
    </template>
  </CModal>
</template>

<script>
export default {
  name: 'DatePickerModal',
  props: {
    enable_close_btn: {type:Boolean, default:false},
    title: { type: String, default: 'Confirm' },
    color: {type:String, default: 'primary'},
    size: { type: String, default: 'sm' },
    ok_text: {type: String, default: 'OK'},
    cancel_text: {type: String, default: 'Cancel'}
  },
  data() {
    return {
      show: false,
      start_time: null,
      end_time: null,
      sts: null,
      ets: null
    }
  },
  methods: {
    open() {
      this.finalize();
      this.show = true;
    },
    finalize() {
      this.start_time = null;
      this.end_time = null;
      this.sts = null;
      this.ets = null;
      this.show = false;
    },
    onCancel() {
      this.$emit('cancel');
      this.finalize();
    },
    onOkay() {
      if (!this.start_time) {
        this.$notify.warning({
          title: this.$t('cloud.title.alert'),
          message: this.$t('cloud.title.err_search_from'),
          offset: 30
        });
        return;
      }
      if (!this.end_time) {
        this.$notify.warning({
          title: this.$t('cloud.title.alert'),
          message: this.$t('cloud.title.err_search_to'),
          offset: 30
        });
        return;
      }
      this.sts = this.start_time.getTime();
      this.ets = this.end_time.getTime();
      this.$emit('ok', {sts: this.sts, ets: this.ets});
      this.finalize();
    }
  }
}
</script>